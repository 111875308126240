<template>
  <div class="fill-height" v-if="vm.walletAddresses.length > 0">
    <div class="background-1">
      <v-container class="dashboard-container pt-8">
        <v-row>
          <v-col cols="12">
            <div
              class="d-flex"
              :class="{
                'row-1-desktop flex-row': $vuetify.breakpoint.smAndUp,
                'row-1-mobile flex-column': $vuetify.breakpoint.xs,
              }"
              style="align-items: stretch !important"
            >
              <div class="row-1-left d-flex flex-column">
                <card-hover :disable-hover="true">
                  <div class="d-flex flex-column network-container">
                    <v-theme-provider dark class="money-net">
                      <v-card
                        class="pa-6 pb-5 d-flex justify-space-between align-end money-net__card"
                        rounded="xl"
                        elevation="0"
                      >
                        <div class="d-flex flex-column">
                          <div>Net Worth</div>
                          <div class="text-h4 font-weight-bold mt-2">
                            {{ vm.totalNetWorth | round(2) | usd }}
                          </div>
                        </div>
                        <img height="48" src="../../../assets/icons/dashboard-star.svg" contain />
                      </v-card>
                    </v-theme-provider>
                    <table class="money-percent pa-6">
                      <tbody>
                        <tr>
                          <td>Wallet</td>
                          <td style="width: 100%" class="px-4">
                            <v-progress-linear :value="vm.walletPercent"></v-progress-linear>
                          </td>
                          <td>{{ vm.walletPercent | round(2) }}%</td>
                        </tr>
                        <tr>
                          <td>Investment</td>
                          <td style="width: 100%" class="px-4">
                            <v-progress-linear :value="vm.investPercent"></v-progress-linear>
                          </td>
                          <td>{{ vm.investPercent | round(2) }}%</td>
                        </tr>
                        <tr>
                          <td>Staking</td>
                          <td style="width: 100%" class="px-4">
                            <v-progress-linear :value="vm.farmPercent"></v-progress-linear>
                          </td>
                          <td>{{ vm.farmPercent | round(2) }}%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </card-hover>
                <card-hover
                  class="mt-6 fill-height"
                  :disable-hover="true"
                  :style="$vuetify.breakpoint.smAndUp ? 'max-height: 400px' : ''"
                  :class="vm.walletTokens.length > 0 || vm.walletLoading ? 'row-3-farm' : 'row-3-farm-no-margin'"
                >
                  <div class="d-flex align-center medium-text font-weight-bold header-card-bg px-6 py-3">
                    <v-img
                      :src="require(`@/assets/icons/farm-pig-icon.svg`)"
                      max-width="24"
                      contain
                      class="mr-3"
                    ></v-img>
                    Farming
                  </div>
                  <div class="px-6 mt-6" v-if="vm.isFarmStaked">
                    <div class="d-flex justify-space-between">
                      <div class="d-flex align-center">
                        <v-img
                          class="mr-3"
                          v-if="vm.tierImage"
                          :src="vm.tierImage"
                          max-height="57"
                          contain
                          max-width="43"
                        ></v-img>
                        <div>
                          <div class="text-body-2">YOUR RANK</div>
                          <div class="font-weight-medium text-h6">{{ vm.userTier }}</div>
                        </div>
                      </div>
                      <v-btn small class="primary--text" outlined rounded to="/staking"> Stake </v-btn>
                    </div>
                    <div
                      class="rounded-lg pa-3 my-2"
                      :style="`border: 1px solid ${$vuetify.theme.dark ? 'grey' : 'white'}`"
                    >
                      <div class="d-flex justify-space-between text-body-1 font-weight-medium">
                        <div class="grey1--text">Deposited</div>
                        <div>{{ vm.farmStakedAmount | formatNumber }} WAG</div>
                      </div>
                      <div class="d-flex justify-space-between text-body-1 font-weight-medium">
                        <div class="grey1--text">Value</div>
                        <div>{{ vm.farmStakedValue | round(2) | usd }}</div>
                      </div>
                      <div class="d-flex justify-space-between text-body-1 font-weight-medium">
                        <div class="grey1--text">Lock time</div>
                        <div>{{ vm.farmLockDays | round(0) }} days</div>
                      </div>
                    </div>
                    <div class="text-body-1 font-weight-medium d-flex flex-column mt-2 mb-6">
                      <div>{{ vm.remainFarmLockDays.message }}</div>
                      <v-progress-linear class="mt-2" height="8" rounded :value="vm.remainFarmLockPercent" />
                    </div>
                  </div>
                  <div
                    class="px-6 mt-12 d-flex flex-column justify-center align-center"
                    v-else
                    :class="{
                      'pb-6': $vuetify.breakpoint.xs,
                    }"
                  >
                    <v-img
                      :src="require(`@/assets/icons/waggle-no-data.${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
                      max-width="55px"
                      max-height="56px"
                      contain
                    ></v-img>
                    <div class="text-h5 font-weight-bold text-center mt-2">You have no farming!</div>
                    <connect-metamask requiredChain="sol" :requiredChainId="vm.requiredFarmChainId" class="mt-4">
                      <v-btn class="primary--text" text to="/staking">
                        Start staking $WAG <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </connect-metamask>
                  </div>
                </card-hover>
              </div>

              <div
                class="row-1-right d-flex flex-column"
                :class="{
                  'mt-6': $vuetify.breakpoint.xs,
                }"
              >
                <card-hover :disable-hover="true" class="full-height">
                  <div class="d-flex justify-space-between align-center header-card-bg px-6 py-1">
                    <div class="medium-text font-weight-bold">
                      Your wallet list<v-chip
                        v-if="$vuetify.breakpoint.mdAndUp"
                        class="ma-2 text-body-1 font-weight-bold"
                        style="background: rgba(241, 177, 1, 0.2)"
                        text-color="primary"
                      >
                        {{ vm.walletAddresses.length }}
                      </v-chip>
                    </div>
                    <v-btn class="text-none btn-text" color="primary" rounded depressed medium @click="addWallet">
                      Settings
                      <!-- <v-icon size="16" class="ml-2">mdi-pencil</v-icon> -->
                    </v-btn>
                  </div>
                  <div class="pb-6 pt-4 d-flex flex-column" style="height: calc(100% - 56px)">
                    <div style="height: calc(100% - 148px - 24px); overflow-y: auto" class="pl-6">
                      <div v-for="(address, index) in vm.walletAddresses" :key="address">
                        <div class="d-flex align-center py-3">
                          <div class="font-weight-medium mr-2" style="min-width: 20px">{{ index + 1 }}.</div>
                          <address-copy-board
                            :address="address || 'TBA'"
                            :isShortAddress="true"
                            :shortStartAmount="$vuetify.breakpoint.mdAndUp ? 10 : 5"
                            :shortEndAmount="$vuetify.breakpoint.mdAndUp ? 5 : 3"
                          />
                          <v-spacer></v-spacer>
                          <div class="pr-6" v-if="vm.walletInvestors[address] === 'loading'">
                            <v-progress-circular
                              :width="3"
                              :size="24"
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </div>
                          <div class="pr-4" v-else-if="vm.walletInvestors && vm.walletInvestors[address]">
                            <div v-if="!vm.walletInvestors[address].synapsSessionId">KYC Not submitted</div>
                            <div class="d-flex" v-else-if="vm.walletInvestors[address].status === 'kyc-verified'">
                              <v-icon color="success">mdi-check-circle-outline</v-icon>
                              <div class="success--text ml-2">KYC Passed!</div>
                            </div>
                            <div class="d-flex" v-else-if="vm.walletInvestors[address].status === 'kyc-banned'">
                              <v-icon color="error">mdi-alert-circle-outline</v-icon>
                              <div class="error--text ml-2">KYC Failed!</div>
                            </div>
                            <div class="d-flex" v-else-if="vm.walletInvestors[address].status === 'kyc-pending'">
                              <v-icon color="primary">mdi-alert-circle-outline</v-icon>
                              <div class="primary--text ml-2">KYC Pending</div>
                            </div>
                            <div class="d-flex" v-else>
                              <v-icon color="error">mdi-alert-circle-outline</v-icon>
                              <div class="error--text ml-2">Not submitted</div>
                            </div>
                          </div>
                          <div class="d-flex pr-4" v-else>
                            <v-icon color="error">mdi-alert-circle-outline</v-icon>
                            <div class="error--text ml-2">Not submitted</div>
                          </div>
                        </div>
                        <v-divider v-if="index !== vm.walletAddresses.length - 1"></v-divider>
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                    <div
                      class="border-radius-16 py-6 mx-6 text-center mt-2"
                      :class="$vuetify.theme.dark ? 'gray-border' : 'primary-border'"
                    >
                      <div>Fill out our KYC form to Invest in amazing projects on Waggle</div>
                      <v-btn
                        class="px-3 py-5 text-none btn-text mt-4"
                        @click="goToSubmitKYC"
                        color="primary"
                        outlined
                        rounded
                      >
                        Submit KYC
                      </v-btn>
                    </div>
                  </div>
                </card-hover>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <div
              class="d-flex"
              :class="{
                'row-2-desktop flex-row': $vuetify.breakpoint.smAndUp,
                'row-2-mobile flex-column': $vuetify.breakpoint.xs,
              }"
            >
              <card-hover class="row-2-wallet pb-6 d-flex flex-column" :disable-hover="true">
                <div class="d-flex align-center header-card-bg px-6 py-3">
                  <div class="medium-text font-weight-bold d-flex">
                    <v-img :src="require(`@/assets/icons/wallet-icon.svg`)" max-width="20" contain class="mr-3"></v-img>
                    Wallet Portfolio Exposure
                  </div>
                </div>
                <div class="flex-grow-1 d-flex align-center px-6 mt-6" v-if="vm.top6Wallets.length > 0">
                  <v-row>
                    <v-col class="pa-0">
                      <div class="d-flex align-center justify-center fill-width fill-height">
                        <VueApexCharts
                          :options="walletCharOptions"
                          :series="vm.walletSeries"
                          width="180"
                          height="180"
                        />
                      </div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="d-flex align-center fill-width fill-height">
                        <table class="fill-width">
                          <tbody>
                            <tr v-for="(item, index) in vm.top6Wallets" :key="index">
                              <td>
                                <div class="circle" :style="`background-color: ${colors[index]}`" />
                              </td>
                              <td class="pl-2 font-weight-medium">
                                {{ item.symbol }}
                              </td>
                              <td style="width: 100%" class="text-end">{{ item.percentage | round(1) }}%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div class="flex-grow-1 d-flex justify-center px-6 mt-6" v-else>
                  <div class="d-flex flex-column align-center ma-6">
                    <v-img
                      :src="require(`@/assets/icons/waggle-no-data.${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
                      max-width="58px"
                      max-height="58px"
                      contain
                    ></v-img>
                    <div class="text-h6 font-weight-bold text-center">No Token yet!</div>
                  </div>
                </div>
              </card-hover>
              <card-hover :disable-hover="true" class="row-2-top-asset d-flex flex-column">
                <div class="d-flex align-center medium-text font-weight-bold header-card-bg px-6 py-3">
                  <v-img
                    :src="require(`@/assets/icons/wallet-statistic-icon.svg`)"
                    max-width="24"
                    contain
                    class="mr-2"
                  ></v-img>
                  Top 5 Asset
                </div>
                <v-data-table
                  class="data-table font-weight-medium"
                  :items="vm.walletTokens"
                  :headers="walletHeaders"
                  :loading="vm.walletLoading"
                  :items-per-page="5"
                  hide-default-footer
                >
                  <template v-slot:[`item.symbol`]="{ item }">
                    <div>
                      {{ item.symbol }}<span> ({{ item.chain && item.chain.toUpperCase() }})</span>
                    </div>
                  </template>
                  <template v-slot:[`item.chain`]="{ item }">
                    <img width="28" height="24" :src="require(`@/assets/icons/${item.chain}-icon.svg`)" />
                  </template>
                  <template v-slot:[`item.balance`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          {{ item.balance | formatNumber(2) }}
                        </div>
                      </template>
                      <span>{{ item.balance }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          {{ item.price | round(3) | usd }}
                        </div>
                      </template>
                      <span>{{ item.price }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.value`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          {{ item.value | round(2) | usd }}
                        </div>
                      </template>
                      <span>{{ item.value }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </card-hover>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="my-4"></v-divider>
      <v-container class="dashboard-container">
        <v-row>
          <v-col cols="12" md="4">
            <card-hover class="py-4 px-6" :darker="false" :disableHover="true">
              <div class="normal-caption-text font-weight-bold">Total investment</div>
              <div class="token-number mt-1">{{ vm.nftCards.length }} NFT</div>
            </card-hover>
          </v-col>
          <v-col cols="12" md="4">
            <card-hover class="py-4 px-6" :darker="false" :disableHover="true">
              <div class="normal-caption-text font-weight-bold">Total remaining value</div>
              <div class="token-number mt-1">{{ vm.totalRemainedInvestValue | usd }}</div>
            </card-hover>
          </v-col>
          <!-- <v-col cols="12" md="4">
            <card-hover class="py-4 px-6" :darker="false" :disableHover="true">
              <div class="normal-caption-text font-weight-bold">Total on sale</div>
              <div class="token-number mt-1">0 NFT</div>
            </card-hover>
          </v-col> -->
        </v-row>

        <div
          class="d-flex mt-6"
          :class="{
            'row-4-desktop flex-row': $vuetify.breakpoint.smAndUp,
            'row-4-mobile flex-column': $vuetify.breakpoint.xs,
          }"
        >
          <card-hover class="row-4-invest pb-6" :disable-hover="true">
            <div class="d-flex align-center header-card-bg px-6 py-3">
              <div class="medium-text font-weight-bold d-flex">
                <v-img :src="require(`@/assets/icons/money-bag-icon.svg`)" max-width="22" contain class="mr-2"></v-img>
                Investment Portfolio
              </div>
            </div>
            <div class="flex-grow-1 d-flex align-center px-6 mt-6" v-if="vm.invests.length">
              <v-row>
                <v-col class="pa-0">
                  <div class="d-flex align-center justify-center fill-width fill-height">
                    <VueApexCharts :options="investChartOptions" :series="vm.investSeries" width="180" height="180" />
                  </div>
                </v-col>
                <v-col class="pa-0">
                  <div class="d-flex align-center fill-width fill-height">
                    <table class="fill-width">
                      <tbody>
                        <tr v-for="(item, index) in vm.top6Invest" :key="index">
                          <td>
                            <div class="circle" :style="`background-color: ${colors[index]}`" />
                          </td>
                          <td class="pl-1 font-weight-medium">
                            {{ item.name }}
                          </td>
                          <td style="width: 100%" class="text-end">{{ item.percentage | round(1) }}%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="flex-grow-1 d-flex justify-center px-6 mt-6" v-else>
              <div class="d-flex flex-column align-center ma-6">
                <div class="text-h5 font-weight-bold text-center mt-4">No Investment yet!</div>
                <div class="text-body-2 my-2 grey1--text">Refer to investment projects listed on Waggle Raise</div>
                <div v-if="!walletStore.account" class="pt-6">
                  <connect-wallet></connect-wallet>
                </div>
                <v-btn v-else class="primary--text" text to="/projects" :color="!vm.isFarmStaked ? 'primary' : 'grey'">
                  Go to Marketplace <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </div>
          </card-hover>
          <card-hover class="row-4-invest-table" :disable-hover="true">
            <div class="medium-text font-weight-bold header-card-bg pl-6 pt-1">
              Investments
              <v-chip
                class="ma-2 text-body-1 font-weight-bold"
                style="background: rgba(241, 177, 1, 0.2)"
                text-color="primary"
              >
                {{ vm.invests.length }}
              </v-chip>
            </div>

            <v-data-table
              class="data-table font-weight-medium table-header"
              :hide-default-footer="!vm.invests || vm.invests.length < 8"
              :headers="investHeaders"
              :items="vm.invests"
              :items-per-page="8"
              :loading="vm.investLoading"
            >
              <template v-slot:[`item.salePrice`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{ item.salePrice | round | usd }}
                    </div>
                  </template>
                  <span>{{ item.salePrice }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.totalAmount`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{ item.totalAmount | formatNumber(2, 0) }}
                    </div>
                  </template>
                  <span>{{ item.totalAmount }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.remainedAmount`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{ item.remainedAmount | formatNumber(2, 0) }}
                    </div>
                  </template>
                  <span>{{ item.remainedAmount }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.redeemedAmount`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{ item.redeemedAmount | formatNumber(2, 0) }}
                    </div>
                  </template>
                  <span>{{ item.redeemedAmount }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.remainedValue`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{ item.remainedValue | formatNumber(2, 0) }}
                    </div>
                  </template>
                  <span>{{ item.remainedValue }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.value`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{ item.value | round | usd }}
                    </div>
                  </template>
                  <span>{{ item.value }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </card-hover>
        </div>
      </v-container>

      <v-container class="dashboard-container">
        <card-hover :disable-hover="true" class="mb-6">
          <div class="d-flex align-center justify-space-between header-card-bg px-6 py-3">
            <div class="timeline-title font-weight-bold">Recent NFT Investments</div>
            <div class="d-flex align-center primary--text cursor-pointer font-weight-bold" @click="goToNftDashboard()">
              View All <v-icon color="primary">mdi-chevron-right</v-icon>
            </div>
          </div>
          <v-row class="pa-6" v-if="vm.shortNftCards && vm.shortNftCards.length">
            <v-col cols="12" md="4" v-for="(nftCard, index) in vm.shortNftCards" :key="index">
              <router-link :to="`/investment-card/${nftCard.keyInfo.id}?chainId=${nftCard.poolStore.chainId}`">
                <div>
                  <HoverImage :keyInfo="nftCard.keyInfo" :poolStore="nftCard.poolStore" :showId="true" />
                </div>
              </router-link>
            </v-col>
          </v-row>
          <div class="subtitle--text text-center pa-4" v-else>No data available</div>
        </card-hover>
        <card-hover class="" :disable-hover="true">
          <div class="d-flex align-center justify-space-between header-card-bg px-6 py-3">
            <div class="d-flex align-center">
              <v-select
                class="border-radius-16 mr-4"
                v-model="vm.selectedToken"
                :items="vm.tokenList"
                item-text="tokenSymbol"
                item-value="id"
                placeholder="token"
                hide-details
                style="max-width: 120px"
                @change="vm.changeSelectedToken($event)"
                dense
                outlined
                return-object
              ></v-select>
              <div v-if="$vuetify.breakpoint.smAndUp">Price Chart</div>
            </div>
            <v-select
              class="border-radius-16"
              v-model="timePeriod"
              :items="timePeriods"
              item-text="title"
              item-value="value"
              placeholder="Period"
              hide-details
              style="max-width: 120px"
              @change="vm.changeselectedDays(timePeriod)"
              dense
              outlined
            ></v-select>
          </div>
          <div class="py-6" style="min-height: 340px">
            <VueApexCharts
              v-if="vm.prices.length"
              :options="vm.priceChartOptions"
              :series="vm.prices"
              width="100%"
              height="280"
            />
          </div>
        </card-hover>
        <card-hover class="mt-6" :disable-hover="true">
          <div class="d-flex align-center justify-space-between header-card-bg px-6 py-3">
            <div class="timeline-title font-weight-bold">News</div>
          </div>
          <v-row class="pa-6">
            <v-col cols="12" md="3" sm="4" v-for="news in vm.poolNews" :key="news.id">
              <news-card :newsObj="news" />
            </v-col>
          </v-row>
        </card-hover>
      </v-container>
    </div>
    <company-footer />
    <add-wallet-address ref="addWalletDialog" />
  </div>
  <dashboard-no-wallet v-else />
</template>

<script>
import { dashboardViewModel } from '../viewmodels/dashboard-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Inject } from 'vue-property-decorator'
import { numberHelper } from '@/helpers/number.hepler'
import { reaction } from 'mobx'
import { round } from 'lodash'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
    AddWalletAddress: () => import('../dialogs/add-wallet-address.vue'),
    DashboardNoWallet: () => import('./dashboard-no-wallet.vue'),
    HoverImage: () => import('@/modules/market/components/hover-image.vue'),
    NewsCard: () => import('@/components/news-card.vue'),
  },
})
export default class IdoPools extends Vue {
  @Inject() providers
  @Provide() vm = dashboardViewModel
  disposers = []
  walletStore = walletStore

  colors = ['#CE4913', '#FF996F', '#FFD05A', '#FFC9B3', '#B4B4B4', '#9A7465']

  walletCharOptions = {
    chart: {
      type: 'donut',
      width: 180,
      height: 180,
    },
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    noData: { text: 'No data available', color: '#B4B4B4' },
    colors: this.colors,
    tooltip: {
      y: {
        formatter: (x) => numberHelper.usdFormat(round(x, 1)),
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Net Worth',
              color: '#B4B4B4',
              fontFamily: 'Proxima Nova',
              fontSize: '12px',
              fontWeight: '400',
              offsetY: 0,
              formatter: function (w) {
                const total = w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
                return numberHelper.usdFormat(round(total, 1))
              },
            },
            value: {
              label: 'Net Worth',
              color: '#B4B4B4',
              fontFamily: 'Proxima Nova',
              fontWeight: '700',
              fontSize: '16px',
              offsetY: 2,
              formatter: function (w) {
                return numberHelper.usdFormat(round(w, 1))
              },
            },
          },
        },
      },
    },
  }
  investChartOptions = {
    chart: {
      type: 'donut',
      width: 180,
      height: 180,
    },
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    colors: this.colors,
    noData: { text: 'No data available' },
    tooltip: {
      y: {
        formatter: (x) => numberHelper.usdFormat(round(x, 1)),
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Net Worth',
              color: '#B4B4B4',
              fontFamily: 'Proxima Nova',
              fontSize: '12px',
              fontWeight: '400',
              offsetY: 0,
              formatter: function (w) {
                const total = w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
                return numberHelper.usdFormat(round(total, 2))
              },
            },
            value: {
              label: 'Net Worth',
              color: '#B4B4B4',
              fontFamily: 'Proxima Nova',
              fontWeight: '700',
              fontSize: '16px',
              offsetY: 2,
              formatter: function (w) {
                return numberHelper.usdFormat(round(w, 2))
              },
            },
          },
        },
      },
    },
  }
  walletHeaders = [
    { text: 'Asset', value: 'symbol', sortable: false },
    { text: '', value: 'chain', sortable: false },
    { text: 'Balance', value: 'balance', sortable: false },
    { text: 'Price', value: 'price', sortable: true },
    { text: 'Value', value: 'value', sortable: true },
  ]
  investHeaders = [
    { text: 'Project Investment', value: 'name', sortable: false },
    { text: 'Token', value: 'tokenName', sortable: false },
    { text: 'Purchased amount', value: 'totalAmount', sortable: false },
    { text: 'Unlocked amount', value: 'redeemedAmount', sortable: false },
    { text: 'Locked amount', value: 'remainedAmount', sortable: false },
    { text: 'Price', value: 'salePrice', sortable: false },
    { text: 'Total Value', value: 'value', sortable: true },
    { text: 'Remaining Value', value: 'remainedValue', sortable: true },
  ]
  timePeriod = '1'
  timePeriods = [
    {
      title: '24h',
      value: '1',
    },
    {
      title: '7 days',
      value: '7',
    },
    {
      title: '30 days',
      value: '30',
    },
  ]

  created() {
    this.disposers = [
      reaction(
        () => this.vm.walletSeries,
        () => {
          this.walletCharOptions = {
            ...this.walletCharOptions,
            labels: this.vm.top6Wallets.map((w) => w.symbol),
          }
        }
      ),
      reaction(
        () => this.vm.investSeries,
        () => {
          this.investChartOptions = {
            ...this.investChartOptions,
            labels: this.vm.top6Invest.map((w) => w.name),
          }
        }
      ),
    ]
    this.vm.loadData()
  }

  async addWallet() {
    const newAddresses = await this.$refs.addWalletDialog.open()
    if (newAddresses) {
      this.vm.changeAddresses(newAddresses)
      this.vm.loadData()
    }
  }

  goToSubmitKYC() {
    this.$router.push('/submit-kyc')
  }

  goToNftDashboard() {
    this.$router.push('/dashboard/investment-nft')
  }

  destroyed() {
    this.disposers.forEach((d) => d())
  }
}
</script>

<style scoped lang="scss">
.row-1-desktop {
  .row-1-left {
    width: 50%;
    margin-right: 12px;
    height: 588px;
  }
  .row-1-right {
    width: 50%;
    margin-left: 12px;
    height: 588px;
  }
}
.row-1-mobile {
  .row-1-network {
    /* width: 50%; */
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .row-1-farming {
    margin-bottom: 12px;
  }
  .row-1-wallet {
    /* width: 50%; */
    margin-top: 12px;
  }
}
.row-2-desktop {
  .row-2-wallet {
    width: 33%;
    margin-right: 12px;
  }
  .row-2-top-asset {
    width: 67%;
    margin-left: 12px;
  }
}
.row-2-mobile {
  .row-2-wallet {
    /* width: 50%; */
    margin-bottom: 12px;
  }
  .row-2-top-asset {
    margin-top: 12px;
  }
  .row-2-invest {
    /* width: 50%; */
    margin-top: 12px;
  }
}

.row-3-desktop {
  .row-3-statistic-money {
    width: 60%;
    margin-right: 12px;
  }
  .row-3-farm {
    width: 40%;
    margin-left: 12px;
  }
  .row-3-farm-no-margin {
    width: 40%;
  }
}
.row-3-mobile {
  .row-3-statistic-money {
    /* width: 50%; */
    margin-bottom: 12px;
  }
  .row-3-farm {
    /* width: 50%; */
    margin-top: 12px;
  }
}
.row-4-desktop {
  .row-4-invest {
    width: 33%;
    margin-right: 12px;
  }
  .row-4-invest-table {
    width: 67%;
    margin-left: 12px;
  }
}
.row-4-mobile {
  .row-4-invest {
    /* width: 50%; */
    margin-bottom: 12px;
  }
  .row-4-invest-table {
    /* width: 50%; */
    margin-top: 12px;
  }
}

.network-container {
  position: relative;
  &--desktop {
    .money-net {
      width: 50%;
    }
    .money-percent {
      width: 50%;
    }
  }
}

.data-table {
  background: transparent;
}
.dashboard-container {
  max-width: 1232px !important;
}
.circle {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  display: inline-block;
}
.bee-image {
  position: absolute;
  right: 22%;
  bottom: 22%;
}
.theme--dark {
  .box-gradient {
    background: linear-gradient(to right, rgba(68, 56, 69), rgb(48, 36, 49)) padding-box,
      linear-gradient(to left bottom, #fdba12 0%, #dd5789 73%, #491999 100%) border-box;
    border: 1px solid transparent;
    border-radius: 16px;
    display: inline-block;
  }
  .header-card-bg {
    background: linear-gradient(rgba(255, 199, 73, 0.05) 0%, rgba(255, 255, 255, 0.1) 100%);
  }
  .money-net__card {
    background: linear-gradient(to left, #ffca44 0.29%, #dd5789 52.21%, #491999 100%);
    border: 3px solid #ffffff;
    box-sizing: border-box;
  }
}
.theme--light {
  .box-gradient {
    border-radius: 16px;
    border: 1px solid white;
  }
  .header-card-bg {
    background: #fdf0f5 !important;
  }
  .money-net__card {
    background: linear-gradient(230.99deg, #ffc372 -1.47%, #dd5789 34.42%, #781999 69.63%, #491999 100.99%);
    border: 3px solid #ffffff;
    box-sizing: border-box;
  }
}
</style>
